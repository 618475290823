<template>

</template>

<script>
    import localforage from 'localforage';
    import router from "../router";
    import Vue from "vue";
    export default {
        name: 'Index',
        mounted() {
            localforage.getItem('usuario').then((usuario) => {
                if (usuario) {
                    this.$router.replace({name: 'dashboard'});
                } else {
                    router.replace({path:'/login', query: { returnUrl: this.$route.path }});
                }
            });
        },
    };
</script>

<style scoped>

</style>
